import $ from 'jquery';
import axios from 'axios'

const auth = {};



auth.localAuthenticate = async(username, password) => {
    if ( !username || !password ) {
      $('#loginRegisterCodeError').text("Please enter a username and password");
      throw new Error('Please supply both a username and a password');
    }
    return await axios.post('/login/local', { username : username, password : password }).then( res => res.data)
}

auth.consent = (accessCode) => {
  console.log('callng auth consent')
  return axios.post('/u/consent', { code: accessCode }).then( res => res.data);
}

auth.preCheckRegister = async(username, email, password) => {
    return await axios.post('/u/registrationPreCheck', {
      username: username,
      email: email,
      password: password
    }).then( res => res.data);
}

auth.registerAndConsent = (username, email, password, consented, accessCode) => {
  return axios.post('/u/register', {
    username: username,
    email: email,
    password: password,
    consented: consented,
    code: accessCode
  })
  .then( res => res.data);
}

auth.googleAuthenticate = async() => {
  try {
    const auth2 = gapi.auth2.getAuthInstance();
    await auth2.signIn()
    if ( auth2.isSignedIn.get() ) {
      const googleUser = await auth2.currentUser.get();
      const id_token = googleUser.getAuthResponse().id_token;
      const { data } = await axios.post('/login/google', { id_token : id_token })
      if ( !data.hasOwnProperty('_id') ) { throw new Error('Google user returned userId unsucessful') }
      return data
    } else {
      throw new Error('Google user login unsucessful')
    }
  } catch (e) {
    console.error('auth.googleAuthenticate error', e)
  }
}

auth.loggedInWithGoogle = () => gapi.auth2.getAuthInstance().isSignedIn.get()

//This is currently just used to sync login state if a user is logged in with google but unknown to server w/o page refresh
auth.googleLogout = () => {
  const auth2 = gapi.auth2.getAuthInstance();
  return auth2.signOut()
}


auth.signOut = async() => {
  try {
    const auth2 = gapi.auth2.getAuthInstance();
    if ( auth2.isSignedIn.get() ) {
      await auth2.signOut()
    }
    window.location.href = '/logout' //server side signOut
  } catch (e) {
    console.error('signout error', e)
  }

}

export { auth as default }

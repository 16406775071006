import $ from 'jquery';
import axios from 'axios';
import auth from './auth.js';
const loginAndRegister = {};

loginAndRegister.state = 'login';

const initHandlers = () => {

  $('#googleSignin').on('click', async(el) => {
    el.preventDefault();
    const user = await auth.googleAuthenticate();
    location.reload()
  })

  $('#nextButton').on('click', async(el) => {
    el.preventDefault();
    if(loginAndRegister.state == 'login') {
        await loginAndRegister.localLogin();
    }
    else if(loginAndRegister.state == 'register')  {
        await loginAndRegister.validateRegistration();
    }
    else if(loginAndRegister.state == 'consent')  {
        await loginAndRegister.registerAndConsent();
    }
  })

  $('#signOutButton').on('click', (el) => {
    //el.preventDefault();
    window.location.href = '/logout'
  })

  $('#registerOrSignInButton').on('click', (el) => {
    el.preventDefault();
    if(loginAndRegister.state == 'login') {
      loginAndRegister.showRegistration();
    }
    else if(loginAndRegister.state == 'register')  {
        loginAndRegister.showLogin();
    }
  })

  $('#consentRegistrationButton').on('click', async(el) => {
    el.preventDefault();
    await loginAndRegister.validateRegistration();
  })

  $('#forgotPassword').on('click', (el) => {
    el.preventDefault();
    //state is in login
    loginAndRegister.showForgot();
  })

  $('#resetPassword').on('click', (el) => {
    el.preventDefault();
    console.log("reset password has been clicked");
    //forgot help message
    $('#forgotHelp').text("An email to reset your password has been sent to ... ");
    $('#forgotHelp').css("background-color", '#A3C233');
    $('#forgotHelp').css("font-style", 'normal');
    $('#forgotHelp').css("color", '#fff');
    $('#forgotHelp').css("font-size", '16px');
    $('#forgotHelp').css("width", '100%');
    //hide button and form
    $('#resetPassword').css('opacity', '0');
    $('#resetPassword').attr("disabled", true);
    $('#forgetEmail').css('opacity', '0');

    let email = $('#forgetEmail').val()
    axios.post('/u/requestResetPassword', {
      email: email
    })
  })

  $('#backtoLogin').on('click', (el) => {
    el.preventDefault();
    console.log("return to sign in has been clicked!");
    loginAndRegister.showLogin();
  })

  $('#backToRegister').click(loginAndRegister.showRegistration)

  $('#inputUsername').keyup(loginAndRegister.checkNextButton)
  $('#inputUsername').change(loginAndRegister.checkNextButton)
  $('#inputPassword').keyup(loginAndRegister.checkNextButton)
  $('#inputPassword').keydown(loginAndRegister.nextOnEnter)
  $('#inputPassword').change(loginAndRegister.checkNextButton)

  $('#registerEmail').keyup(loginAndRegister.checkNextButton)
  $('#registerEmail').change(loginAndRegister.checkNextButton)

  $('#understandCheck').change(loginAndRegister.checkNextButton)
  $('#accessCodeInput').change(loginAndRegister.checkNextButton)
  $('#accessCodeInput').keyup(loginAndRegister.checkNextButton)

  $('#continueAsGuest').on('click', (el) => {
    el.preventDefault()
    const macheKey = $('#landingContainer').attr('data-returnToMache');
    console.log('sending ', macheKey)
    window.location = `/e/continueAsGuest?macheKey=${macheKey}`
  })




  $('#signInLogIn').on('click', async(el) => {
    el.preventDefault();
    el.stopPropagation();
    await loginAndRegister.showLoginModal();
  })
}

//since you may still be signed in with google but unknown to server
const syncLoginState = async() => {
  try {
    const isAuthenticated = await axios.get('/u/loginStatus').then( res => res.data.isAuthenticated )
    if ( !isAuthenticated && auth.loggedInWithGoogle() ) {
      await auth.googleLogout()
    }
  } catch (err) {
    console.error('sync login status failed', err)
  }
}

const possiblyForceConsent = () => {
  if ( window.user && window.user.userFlags && !window.user.userFlags.consented ) {
    loginAndRegister.showConsent();
  }
}



//assumming asyncin future
loginAndRegister.init = async () => {
  initHandlers();
  loginAndRegister.showLogin();
  possiblyForceConsent();
  setTimeout(syncLoginState, 500) // hacky but init is sometimes not finished by time of google platform load

  $('#landingContainer').css('opacity', '1');
  $('#nextButton').prop('disabled', true);
}

loginAndRegister.checkNextButton = () => {
  if(loginAndRegister.state == "login") {
    if($('#inputUsername').val().length > 0 && $('#inputPassword').val().length > 0 ) {
      $('#nextButton').prop('disabled', false);
    } else {
      $('#nextButton').prop('disabled', true);
    }
  }
  else if(loginAndRegister.state == "register") {
    if ( $('#inputUsername').val().length > 0
      && $('#registerEmail').val().length > 0
      && $('#inputPassword').val().length > 0) {
      $('#nextButton').prop('disabled', false);
    } else {
      $('#nextButton').prop('disabled', true);
    }
  }
  else if(loginAndRegister.state == "consent") {
    if ( $('#accessCodeInput').val().length > 0
      && $("#understandCheck").prop('checked')) {
        $('#nextButton').prop('disabled', false);
    } else {
      $('#nextButton').prop('disabled', true);
    }
  }
}

loginAndRegister.nextOnEnter = (e) => {
  if(e.keyCode == 13) {
    if(loginAndRegister.state == "login" || loginAndRegister.state == "register") {
      $('#nextButton').click();
    }
  }
}

loginAndRegister.localLogin = async () => {
    loginAndRegister.clearErrors();
    $('#nextButton').text("Signing In...");
    await sleep(400); // gotta build the suspense - nic
    const loginResponse = await auth.localAuthenticate( $('#inputUsername').val(), $('#inputPassword').val() );
    if ( loginResponse._id ) {
      location.reload()
    }
    else { // assume it is an error response
      $('#loginRegisterError').text(loginResponse);
    }
    $('#nextButton').text("Sign In");
}

loginAndRegister.validateRegistration = async () => {
    loginAndRegister.clearErrors();
    $('#nextButton').text("Checking...");
    await sleep(400); // gotta build the suspense - nic
    const preCheckResponse = await auth.preCheckRegister(
       $('#inputUsername').val(),
       $('#registerEmail').val(),
       $('#inputPassword').val()
    );

    if ( preCheckResponse.success) {
        loginAndRegister.showConsent();
        loginAndRegister.state = 'consent';
    }
    else {
      $('#loginRegisterError').text(preCheckResponse);
      $('#nextButton').text("Next");
    }
}

loginAndRegister.registerAndConsent = async () => {
  loginAndRegister.clearErrors();
  const userStatus = await axios.get('/u/loginStatus').then(res => res.data)
  if (userStatus.isAuthenticated) {
    //in this case they are a google or fb user who has not consented
    if (userStatus.isConsented)
      location.reload();
    else {
      const codeResponse = await auth.consent($('#accessCodeInput').val())
      // console.log('reg code response', codeResponse);
      if (codeResponse == true)
        location.reload();
      else {
        $('#accessCodeError').text(codeResponse)
      }
    }
  } else {
    const registerResponse = await auth.registerAndConsent(
      $('#inputUsername').val(),
      $('#registerEmail').val(),
      $('#inputPassword').val(),
      true,
      $('#accessCodeInput').val()
    );
    if (registerResponse.success)
      location.reload();
    else {
      $('#accessCodeError').text(registerResponse)
    }
  }
}

loginAndRegister.showLogin = () => {
  $("#loginBox").removeClass().addClass("login-state");
  $("#landingContainer").removeClass().addClass("login-state");
  $("#loginContainer").removeClass().addClass("login-state");
/*
  $('#backToRegister').css('left', '-300px');
  $('#backToRegister').css('display', 'block');
*/
  $('#inputUsername').attr("placeholder", "Username or Email");
  loginAndRegister.clearInputs();

  $('#nextButton').text("Sign In");
  $('#createAcctText').text("Don't have an account?");
  $('#registerOrSignInButton').text("Create one!");

  loginAndRegister.state = 'login';
  loginAndRegister.clearErrors();
}

loginAndRegister.showRegistration = () => {
  $("#loginBox").removeClass().addClass("register-state");
  $("#landingContainer").removeClass().addClass("register-state");
  $("#loginContainer").removeClass().addClass("register-state");

  $("#loginBoxHeader").text("Create Account");

  $('#inputUsername').attr("placeholder", "Username");
  if(loginAndRegister.state != "consent")
    loginAndRegister.clearInputs();

  $('#nextButton').text("Next");

  $('#createAcctText').text("Already have an account?");
  $('#registerOrSignInButton').text("Sign in here!");

  loginAndRegister.state = 'register';
  loginAndRegister.checkNextButton();
  loginAndRegister.clearErrors();
}

loginAndRegister.showForgot = () => {
  console.log("forgot has been clicked!");

  $("#loginBox").removeClass().addClass("forgot-state");
  $("#landingContainer").removeClass().addClass("forgot-state");
  $("#loginContainer").removeClass().addClass("forgot-state");

  // hide all the stuff
  //$("#loginBox").css("height", "0px");
  //$("#loginBox").css("overflow", "hidden");
  /*
  $("#oauthBox").css("height", "0px");
  $("#oauthBox").css("overflow", "hidden");
  $("#createAcctBox").css("height", "0px");
  $("#createAcctBox").css("overflow", "hidden");
  $("#nextButton").css("visibility", "hidden");
  //shrink image logo
  $("#logoLarge").css("height", "280px");
  //show forgot stuff
  $("#forgotBox").css("height", "auto");
  $("#forgotBox").css("opacity", "1");
  $("#forgotBox").css("visibility", "visible");
  $("#nextButton").text("Reset");
  //forgot header transition
  $("#forgotHeader").css("visibility", "visible");
  $("#forgotHeader").css("opacity", "1");
  $("#forgotHeader").css("height", "28px");
  */

}

loginAndRegister.showConsent = () => {
  $("#loginBox").removeClass().addClass("consent-state");
  $("#landingContainer").removeClass().addClass("consent-state");
  $("#loginContainer").removeClass().addClass("consent-state");

  $('#nextButton').text("Agree and Continue");
  $('#loginBoxHeader').text("Informed Consent Policy");

  loginAndRegister.state = 'consent';
  loginAndRegister.checkNextButton();
  loginAndRegister.clearErrors();
}

loginAndRegister.showLoginModal = () => {
  $('#loginModal').removeClass().addClass("open-state");
  loginAndRegister.showLogin();
}

loginAndRegister.clearErrors = () => {
  $('#loginRegisterError').text(" ");
  $('#accessCodeError').text(" ");
}

loginAndRegister.clearInputs = () => {
  $('#inputUsername').val('');
  $('#registerEmail').val('');
  $('#inputPassword').val('');
  $('#accessCodeInput').val('');
  $('#understandCheck').prop('checked', false);
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export { loginAndRegister as default }
